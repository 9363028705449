import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faIndustry, faPlusCircle, faSchool, faSearch, faAddressBook, faTrash, faPen, faUser, faCheck, faIdCard, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';


export default function BusinessDetails(props) {

    const PAGE_TITLE = "Cliente";
    const PAGE_ICON = faIndustry;
    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [color, setColor] = React.useState("");
    const [name, setName] = React.useState("");

    const [businessContacts, setBusinessContacts] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [showNewContact, setShowNewContact] = React.useState(false);
    const [showNewContactData, setShowNewContactData] = React.useState(false);
    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const [showSureEditCustomer, setShowSureEditCustomer] = React.useState(false);
    const [newContactName, setNewContactName] = React.useState('');
    const [currentContact, setCurrentContact] = React.useState('');
    const [currentContactName, setCurrentContactName] = React.useState('');
    const [currentContactType, setCurrentContactType] = React.useState('Seleccionar...');
    const [newContactData, setNewContactData] = React.useState('');
    const [isEditing, setIsEditing] = React.useState(false);
    const [customerBalanceCol, setCustomerBalanceCol] = React.useState([]);
    const [customerBalance, setCustomerBalance] = React.useState([]);
    const [customerBalanceIndicators, setCustomerBalanceIndicators] = React.useState([]);

    const [showDeleteCustomerBalanceModal, setShowDeleteCustomerBalanceModal] = React.useState(false);
    const [selectedCustomerBalanceId, setSelectedCustomerBalanceId] = React.useState("0");
    const handleCloseDeleteCustomerBalanceModal = () => { setShowDeleteCustomerBalanceModal(false); };
    const handleShowDeleteCustomerBalanceModal = (id) => {
        setSelectedCustomerBalanceId(id)
        setShowDeleteCustomerBalanceModal(true);
    }

    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    const handleCloseNewContact = () => setShowNewContact(false);
    const handleShowNewContact = () => setShowNewContact(true);
    const handleCloseNewContactData = () => setShowNewContactData(false);
    const handleShowNewContactData = (contactId) => {
        setCurrentContact(contactId);
        setShowNewContactData(true);
    };
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setCurrentContactName(name);
        setCurrentContact(id);
        setShowSureDelete(true);
    };

    const [showDeleteBusinessModal, setShowDeleteBusinessModal] = React.useState(false);
    const handleCloseDeleteBusinessModal = () => { setShowDeleteBusinessModal(false); };
    const handleShowDeleteBusinessModal = () => {
        setShowDeleteBusinessModal(true);
    }
    const handleCloseSureEditCustomer = () => setShowSureEditCustomer(false);
    const handleShowSureEditCustomer = () => setShowSureEditCustomer(true);


    const handleProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleColor = (event) => {
        setColor(event.target.value);
    };

    const handleIdentifier = (event) => {
        getIdentifier(event.target.value)
    };

    const handleNewContactName = (event) => {
        setNewContactName(event.target.value);
    };

    const handleNewContactData = (event) => {
        setNewContactData(event.target.value);
    };

    const createContact = async function () {
       
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;


        setupTable();
        getContacts()
    }, []);

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getIdentifier = async function (data) {
        
    }

    const getBusinessId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getContacts = async function () {
       let c = [];

       c.push({
        name: "Juan Perez",
        ContactData: [
            {param: "PHON",
            data: "11234234"},
            {param: "EMAI",
            data: "juanperez@gmail.com"}
        ]
       })

       processContacts(c)
    }

    const processContacts = function (data) {
        let result = [];
        let aux;
        let icon;

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if (i === 0) {
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                } else {
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{ marginRight: 10, marginLeft: 2 }} />Agregar datos</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Borrar contacto</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )

            //c.action = <span><Button variant="success" onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{marginRight: 8}}></FontAwesomeIcon>Añadir</Button><Button className='ms-1' variant="danger" onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></span>
            result.push(c);
        }
        setBusinessContacts(result);
    }

    const getBusiness = async function () {
        const req = await get(`/${props.customers ? 'customers' : 'defendants'}/business/byId/${getBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }

    const setupTable = function () {
        setCustomerBalanceCol(['Fecha', 'Carátula', 'A transferir', 'Transferido', ''])
    }

    const changeCurrentContactType = function (type) {
        setCurrentContactType(type);
        document.getElementById('currentContactType').innerHTML = type;
    }

    const createContactData = async function () {
       
    }

    const deleteContact = async function () {
        
    }

    const processBusiness = async function (data) {
        
    }

    const editBusiness = async function () {
        
    }

    const deleteBusiness = async function () {
        
    }

    const getCustomerBalanceById = async (businessId) => {
       
    }

    const processCustomerBalanceById = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowDeleteCustomerBalanceModal(e.id, e.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (e.Proceeding) {
                e.name = e.Proceeding.Debt.name
            } else {
                e.name = '---'
            }

            result.push(e)
        }

        setCustomerBalance(result)
    }

    const deleteCustomerBalance = async () => {
        const req = await del(`/administration/balance`, getUserToken(), { id: selectedCustomerBalanceId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountIndicators = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCustomerBalanceIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>Tostado Cafe Club</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'citas'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                    </Link>


                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar</Button>
                                    <Button className='ms-2' variant="danger" onClick={handleShowDeleteBusinessModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />Eliminar</Button>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={6}>
                                    <table className='w-100'>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>Nombre</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textLegalName" readOnly={!isEditing} placeholder="" value='Tostado Cafe Club' />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>CUIT / DNI</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textCuit" readOnly={!isEditing} placeholder="" value="30-12345123-9" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>Condición IVA</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textCuit" readOnly={!isEditing} placeholder="" value="Responsable Inscripto" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Dirección</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textOrder" readOnly={!isEditing} placeholder="" value="Avenida San Martín 123" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Localidad</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textOrder" readOnly={!isEditing} placeholder="" value="Ciudad de Buenos Aires" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        

                                    </table>
                                </Col>
                                <Col xs={6}>
                                    <table className='w-100'>


                                    </table>
                                </Col>
                            </Row>

                            {isEditing ?
                                <div className='mt-5' style={{ textAlign: 'right' }}>
                                    <Button variant="success" onClick={editBusiness}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar cambios</Button>
                                </div>

                                :
                                <div></div>
                            }

                        </Container>
                    </Card>
                </Row>

            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>

                                    <span>Contactos</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo contacto</Button>
                                </td>

                            </tr>
                        </table>

                        {businessContacts.length > 0 ?
                            <Table className=''>
                                <thead>
                                    <tr>
                                        {tableColumns.map((c, i) => (
                                            <th key={i} className='content-table head'>{c}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessContacts.map((r, i) => (
                                        <tr key={i} >
                                            <td style={{ width: 300 }} className='content-table rows align-middle ps-4'>{r.name}</td>
                                            <td className='content-table rows align-middle'>{r.data}</td>
                                            <td className='content-table rows right align-middle pe-4'>{r.action}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>

                            :
                            <Row style={{ height: 300, textAlign: "center" }}>
                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay contactos</p>
                            </Row>
                        }



                    </Card>
                </Row>

                {
                    props.customers ?

                        <Row>
                            <Card className='cards content mb-5'>
                                <table className='content-options content'>
                                    <tr>
                                        <td className='content-options rows left'>
                                            Balance
                                        </td>
                                        <td className='content-options rows right'>

                                        </td>
                                    </tr>
                                </table>

                                {
                                    customerBalance.length > 0 ?
                                        <div>

                                            <Container>
                                                <Row className='mt-4 mb-4'>

                                                    {customerBalanceIndicators.map((ind, i) => (
                                                        <Col key={i} xs={3}>
                                                            <Card className={`statusCard ${ind.color} administration`}>
                                                                <Card.Body>
                                                                    <Card.Title>{ind.name}</Card.Title>
                                                                    <Card.Text>
                                                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</p>
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))}


                                                </Row>
                                            </Container>

                                            <table striped hover className='mb-3 w-100'>
                                                <thead>
                                                    <tr>
                                                        {customerBalanceCol.map((c, i) => (
                                                            <th key={i} className='content-table head'>{c}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {customerBalance.map((r, i) => (
                                                        <tr key={i} >
                                                            <td className='content-table rows middle align-middle' style={{ width: 150 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                                            <td className='content-table rows middle align-middle'>{r.name}</td>
                                                            <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.debit != null ? `$${pn(r.debit)}` : "---"}</td>
                                                            <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.credit != null ? `$${pn(r.credit)}` : "---"}</td>
                                                            <td className='content-table rows middle align-middle' style={{ width: 100 }}>{r.action}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>

                                        :

                                        <Row style={{ height: 300, textAlign: "center" }}>
                                            <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                            <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay operaciones</p>
                                        </Row>
                                }


                            </Card>
                        </Row>

                        : <div></div>
                }



                <Modal show={showDeleteBusinessModal} onHide={handleCloseDeleteBusinessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar al cliente?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteBusinessModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteBusiness}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContact} centered onHide={handleCloseNewContact}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Nuevo contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre y apellido</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Juan Pérez"
                                        autoFocus
                                        onChange={(event) => handleNewContactName(event)}
                                    />
                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContact}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={createContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContactData} centered onHide={handleCloseNewContactData}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Añadir datos de contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Row>
                                        <Col xs={5}>
                                            <Dropdown className='w-100'>
                                                <Dropdown.Toggle variant="primary" className='w-100' id="dropdown-basic">
                                                    <span id='currentContactType'>Seleccionar...</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Teléfono")}>Teléfono</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Correo electrónico")}>Correo electrónico</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Dirección")}>Dirección</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("DNI")}>DNI</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Cargo")}>Cargo</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Otro")}>Otro</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>

                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                autoFocus
                                                onChange={(event) => handleNewContactData(event)}
                                            />
                                        </Col>
                                    </Row>

                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContactData}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={createContactData}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                    <Modal.Header closeButtonSureDelete>
                        <Modal.Title>Eliminar contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Está seguro de eliminar al contacto {currentContactName}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSureDelete}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={deleteContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteCustomerBalanceModal} onHide={handleCloseDeleteCustomerBalanceModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la operación? Tené en cuenta que no se eliminará el honorario de la cuenta corriente
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteCustomerBalanceModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCustomerBalance}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    )
}